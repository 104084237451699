
import React from "react"

import PaymentEditorPage from "../../components/negosyonow/controls/paymenteditor"


const CoursepaymentsPage = ({location}) => {

	return <PaymentEditorPage
				location={location}
				usagetypeid={5}
				docrefnumlabel={"Course Registration ID"}
				formnotesfieldlabel={"Course Name"}
				customSearchFilter={[{"field":"nnpayment_purpose", "operation":"=", "value": "COURSEFEE"}]}
			/>

}


export default CoursepaymentsPage;
